:root {
  --DinoNeutral01: #393e42;
  --DinoNeutral02: #5f6467;
  --DinoNeutral03: #a4a6ac;
  --DinoNeutral04: #cfd3d6;
  --DinoNeutral05: #e5e8ea;
  --DinoNeutral06: #eff1f2;
  --DinoNeutral07: #fafafb;

  --DinoPrimary01: #007343;
  --DinoPrimary02: #00884f;
  --DinoPrimary03: #00a25e;
  --DinoPrimary04: #00af66;
  --DinoPrimary05: #4dc794;
  --DinoPrimary06: #94d1b8;
  --DinoPrimary07: #e5f7f0;

  --DinoSecondaryDisabled: #bac1b8;
  --DinoSecondary00: #3c4b39;
  --DinoSecondary01: #52634e;
  --DinoSecondary02: #84957f;
  --DinoSecondary03: #baccb3;
  --DinoSecondary04: #d5e8ce;
  --DinoSecondary05: #e4f6dc;

  --DinoTertiaryDisabled: #afc1c3;
  --DinoTertiary00: #1e4d52;
  --DinoTertiary01: #38656a;
  --DinoTertiary02: #6b989d;
  --DinoTertiary03: #a1cfd4;
  --DinoTertiary04: #bcebf0;
  --DinoTertiary05: #caf9fe;

  --DinoAccentDisabled: #b6bcdd;
  --DinoAccent00: #2f3f90;
  --DinoAccent01: #4958a9;
  --DinoAccent02: #7c8be0;
  --DinoAccent03: #b8c3ff;
  --DinoAccent04: #dde1ff;
  --DinoAccent05: #efefff;

  --DinoSuccess01: #05603a;
  --DinoSuccess02: #027a48;
  --DinoSuccess03: #12b76a;
  --DinoSuccess04: #6ce9a6;
  --DinoSuccess05: #d1fadf;

  --DinoError01: #912018;
  --DinoError02: #b42318;
  --DinoError03: #f04438;
  --DinoError04: #fda29b;
  --DinoError05: #fee4e2;

  --DinoWarning01: #93370d;
  --DinoWarning02: #b54708;
  --DinoWarning03: #f79009;
  --DinoWarning04: #fec84b;
  --DinoWarning05: #fef0c7;

  --DinoInfo01: #065986;
  --DinoInfo02: #026aa2;
  --DinoInfo03: #0ba5ec;
  --DinoInfo04: #7cd4fd;
  --DinoInfo05: #e0f2fe;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Source Sans Pro', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--DinoNeutral07);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
