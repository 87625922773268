.collaboratorsPage__withoutCollaboratorsContainer {
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
}

.NotFound_img {
  width: 300px;
  margin-bottom: 20px;
}
p {
  font-weight: 400;
  font-family: Source Sans Pro;
}

.p_none_collabs {
  margin-top: 250px;
  opacity: 0.5;
}

.notFound_screen {
  margin-top: 100px;
}

.knowledgeContainer__collaboratorsGrid--container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.knowledgeContainer__collaboratorsGrid--content {
  max-width: 1525px;
  width: 100%;
}
