.collaboratorsPage__withoutCollaboratorsContainer {
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 75px;
}

.wrapdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NotFound_img {
  width: 300px;
  margin-bottom: 20px;
}
p {
  font-weight: 400;
  font-family: Source Sans Pro;
}
