.login__page--main-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100%;

  .login__page--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;

    .login__button--wrapper {
      margin-top: 2.3rem;
    }
  }
}

.login__text {
  text-align: center;

  .medium-letter {
    font-size: 30px;
    margin: 0;
  }

  .title {
    font-size: 48px;
    margin: 2rem 0 1.5rem;
  }
}

.loginPageImg__container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.loginPageImg__container > img {
  object-fit: contain;
  width: 80%;
  height: 80%;
}

.imgGoogleLogo__styleProps {
  width: 20px;
  height: 20px;
  padding: 0 10px 0 0;
}

.radio-buttons-group {
  display: flex;
  flex-direction: row;
}
