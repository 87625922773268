.topSectionContainer {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(38, 43, 47, 0.4);
  border-radius: 10px;
  justify-content: center;

  .NamePositionArea {
    margin-top: 12px;
    width: 100%;
    height: 100%;
  }
  h1 {
    margin: 0px;
    color: var(--DinoNeutral01);
    font-weight: 400;
    display: flex;
    justify-content: center;
    font-size: 30px;
    letter-spacing: 0.0025em;
  }
  h2 {
    color: var(--DinoNeutral01);
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin: 0px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.015em;
    margin-top: 9px;
  }
}
.paddingDiv {
  padding: 16px;
}
.css-e92xc2.Mui-selected {
  color: var(--DinoNeutral01) !important;
}

.icons_container {
  background-color: var(--DinoPrimary04);
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
