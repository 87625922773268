.knowledgeCatalogGrid__container {
  width: 100%;
  margin-top: 25px;
  min-height: 630px;
  background-color: var(--DinoNeutral05);
  border-radius: 5px;
  padding: 20px;
  padding-top: 55px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0px !important;
  margin-right: 20px;
}

.grid_separated_rows {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.p_none_collabs {
  opacity: 0.5;
  margin: 0px;
}

.emptyListContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.img_notFound {
  display: flex;
  width: 100%;
  margin-top: -100px;
}
