.noUsersText {
  height: 20px;
  width: 231px;
  color: #a4a6ac;
  padding-top: 18%;
  padding-left: 45%;
}

.AuthUserList_UserItem {
  display: flex;
  height: 62px;
  align-items: center;
  padding-bottom: 4px;
}

.authUserList_UserItemTextContainer {
  display: flex;
  flex-direction: column;
}

.users__container {
  width: 100%;
  height: 100%;
  margin-top: 25px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}
