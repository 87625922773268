.notFound__container {
  width: 100%;
  height: 100vh;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 200px;
}

.NotFound_img {
  width: 400px;
  margin-bottom: 40px;
}
.description {
  font-weight: 600;
  font-family: Source Sans Pro;
  text-align: center;
  font-size: 20px;
}
