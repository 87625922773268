.titleText {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.bodyText {
  font-style: normal;
  text-align: center;
  letter-spacing: 0.005em;
}
