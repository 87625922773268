.mainContainer {
  display: flex;
  flex-direction: column;
}

.englishLevelBox {
  display: flex;
  background-color: #fec84b;
  align-self: flex-end;
  margin-top: -10px;
  position: absolute;
  align-items: center;
  border-radius: 3px;
  padding: 4px;
  margin-right: 16px;
}

.englishLevelText {
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  font-weight: 600;
}
