.infoSection__container {
  display: flex;
  gap: 20px;
  margin-top: 24px;

  h1 {
    display: flex;
    justify-content: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  h2 {
    display: flex;
    justify-content: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.015em;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h3 {
    display: inline-block;
    justify-content: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--DinoNeutral01);
    line-height: 20px;
    letter-spacing: 0.02em;
    margin: 0px;
  }
}

.personalInfo__container {
  flex-grow: 2;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(38, 43, 47, 0.4);
  padding: 16px;
  border-radius: 12px;
  max-width: 900px;
}

.laboralInfo__container {
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(38, 43, 47, 0.4);
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
}

.LaboralInfo__grid {
  display: flex;
  flex-direction: column;
}

.englishBirthday__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.englishBirthday__section {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(38, 43, 47, 0.4);
  padding: 16px;
  border-radius: 12px;
  height: 100%;
}
.englishBirthday__section-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
}
