.topTenSkills__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.linearProgress__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.skills__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(38, 43, 47, 0.4);
  padding: 16px;
  border-radius: 12px;

  h1 {
    display: flex;
    justify-content: left;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  h2 {
    display: flex;
    justify-content: left;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.015em;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h3 {
    display: inline-block;
    justify-content: left;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--DinoNeutral01);
    line-height: 20px;
    letter-spacing: 0.02em;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: var(--DinoNeutral03);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modalBtns_container {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
