.knowledgePageContainer__container-closedMenu {
  margin: 90px auto 20px auto;
  background: var(--DinoNeutral07);
  max-width: 1440px;
}

.knowledgePageContainer__container-openedMenu {
  margin: 90px 50px 20px 250px;
  background: var(--DinoNeutral07);
}

@media screen and (max-width: 1440px) {
  .knowledgePageContainer__container-closedMenu {
    margin: 90px 50px 20px 50px;
  }
}
