.knowledgeContainer__container-closedMenu {
  padding: 90px 50px 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.knowledgeContainer__containe-openedMenur {
  padding: 90px 50px 20px 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
