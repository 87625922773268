/* TABS */

// .css-1xjfy4i-MuiButtonBase-root-MuiTab-root.Mui-selected {
//   font-weight: 600;
//   font-family: Source Sans Pro;
//   font-size: 16px;
//   text-transform: none !important;
//   padding-bottom: 200px;
// }

// .Mui-selected {
//   color: rgba(242, 244, 245, 1) !important;
//   font-weight: 600;
//   font-family: Source Sans Pro;
//   font-size: 16px;
//   text-transform: none !important;
//   padding-bottom: 2px;
// }

// .css-1xjfy4i-MuiButtonBase-root-MuiTab-root {
//   color: rgba(242, 244, 245, 1) !important ;
//   font-weight: 400;
//   font-family: Source Sans Pro;
//   text-transform: none !important;
//   padding-bottom: 2px !important;
//   font-size: 16px;
// }
// .css-6rs8o4-MuiTabs-indicator {
//   background-color: rgba(242, 244, 245, 1) !important;
//   border-bottom: 1.5px solid rgba(242, 244, 245, 1) !important;
//   border-radius: 2px;
// }

/* .css-1mogsde {
  color: rgb(0, 0, 0) !important;
}

.css-1gsv261 {
  border-bottom: none !important;
} */

/* SELECT */

.container_menu_select {
  margin-right: 30px;

  .IconUser {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    margin-top: 12px;
    min-width: max-content;
  }
}

/* LOGO */

.Logo_navBar {
  height: 100%;
  display: flex;
  align-content: center;
  padding-right: 30px;
  flex-wrap: wrap;
  min-width: max-content;
}

.Logo_navBar_img {
  width: 144px;
  height: 48px;
}

/* BODY MARGIN */

body {
  margin: 0px;
}

// .tabs_css {
//   width: 100%;
//   position: absolute;
//   top: 0;
//   left: 210px;
// }

// .css-19kzrtu {
//   padding: 24px 0 !important;
// }

.menu-select__user--img {
  width: 100%;
  height: 35px;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.menu-select__user--img > p {
  padding: 0 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 15px;
}

.menu-select__user--img > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}

.optionsComponent__container {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #fafafb;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Source Sans Pro;

  .optionsComponent__item {
    padding: 0 5px 5px 5px;
    margin: 0 10px;
    cursor: pointer;
  }

  .optionComponent__itemIsActive {
    padding-bottom: 5px;
    border-bottom: 3px solid #fafafb;
    border-radius: 1px;
  }
}
