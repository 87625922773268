.clients__container {
  width: 100%;
  height: 100%;
  margin-top: 25px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}

.proyect-info__text {
  font-weight: 600;
}
